export const employees = [
  {
    name: "Kathryn Murphy",
    position: "Buyer 5",
    roleGroup: 2,
    jobRole: "Senior Buyer",
    group: "North Carolina",
    startDate: "Fri, 7 Dec 2020",
    completionDate: "Jan, 24th 2021",
  },
  {
    name: "Thomas Carmichael",
    position: "Buyer 6",
    roleGroup: 2,
    jobRole: "Senior Buyer",
    group: "Florida",
    startDate: "Fri, 7 Dec 2020",
    completionDate: "Jan, 24th 2021",
  },
  {
    name: "Charlotte Schneider",
    position: "Buyer 5",
    roleGroup: 2,
    jobRole: "Senior Buyer",
    group: "Florida",
    startDate: "Fri, 7 Dec 2020",
    completionDate: "Jan, 24th 2021",
  },
  {
    name: "David Cox",
    position: "Procurement Specialist 3",
    roleGroup: 0,
    jobRole: "Procurement Specialist",
    group: "North Carolina",
    startDate: "Fri, 7 Dec 2020",
    completionDate: "Jan, 24th 2021",
  },
  {
    name: "Robert Perez",
    position: "Procurement Support",
    roleGroup: 0,
    jobRole: "Procurement Specialist",
    group: "North Carolina",
    startDate: "Fri, 7 Dec 2020",
    completionDate: "Jan, 24th 2021",
  },
  {
    name: "Renee Taylor",
    position: "Procurement Specialist 4",
    roleGroup: 0,
    jobRole: "Procurement Specialist",
    group: "North Carolina",
    startDate: "Fri, 7 Dec 2020",
    completionDate: "Jan, 24th 2021",
  },
  {
    name: "Alex Lebrun",
    position: "Purchasing Analyst",
    roleGroup: 1,
    jobRole: "Analyst",
    group: "Florida",
    startDate: "Fri, 7 Dec 2020",
    completionDate: "Jan, 24th 2021",
  },
  {
    name: "Theresa Kent",
    position: "Manufacturing Analyst",
    roleGroup: 1,
    jobRole: "Analyst",
    group: "Florida",
    startDate: "Fri, 7 Dec 2020",
    completionDate: "Jan, 24th 2021",
  },
  {
    name: "Margaret Blanchard",
    position: "Operations Analyst",
    roleGroup: 1,
    jobRole: "Analyst",
    group: "North Carolina",
    startDate: "Fri, 7 Dec 2020",
    completionDate: "Jan, 24th 2021",
  },
];
