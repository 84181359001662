<template>
  <div v-click-outside>
    <div v-if="!hasExportPermission">
      <div
        class="h-btn-md rounded justify-center flex ml-auto px-1.5 bg-blue-dark-1 border-blue-dark-1 hover:bg-blue-dark-2 hover:border-blue-dark-2 active:bg-blue-dark-3 active:border-blue-dark-3 disabled:bg-gray-faded-1 disabled:border-gray-faded-1 disabled:text-opacity-30 disabled:pointer-events-none">
        <button class="flex justify-center items-center text-md font-semibold text-white hover:bg-blue-dark-2"
          @click="$emit('download')" data-testid="download-pdf-button">
          Download
        </button>
      </div>
    </div>
    <div v-else>
      <div
        class="h-btn-md rounded justify-center flex ml-auto px-1.5 bg-blue-dark-1 border-blue-dark-1 hover:bg-blue-dark-2 hover:border-blue-dark-2 active:bg-blue-dark-3 active:border-blue-dark-3 disabled:bg-gray-faded-1 disabled:border-gray-faded-1 disabled:text-opacity-30 disabled:pointer-events-none">
        <button class="flex justify-center items-center text-md font-semibold text-white hover:bg-blue-dark-2"
          @click="toggle" data-testid="toggle-button">
          Download
          <div class="h-btn-md border-white border border-b-0 mx-1.5"></div>
          <img src="@/assets/img/icons/arrow-down-white.svg" />
        </button>
      </div>
      <div class="absolute z-10">
        <div class="flex flex-col rounded border border-blue-dark-1 mt-1 relative right-15 bg-white min-w-275"
          :class="visibleDropdown ? '' : 'hidden'">
          <button
            class="text-left font-medium text-md text-grey-dark-1 px-2 py-1 hover:bg-blue-light-5 hover:text-blue-dark-1 focus:outline-none"
            @click="$emit('download')" data-testid="add-button">
            Aggregated Data (.PDF)
          </button>
          <button
            class="text-left font-medium text-md text-grey-dark-1 px-2 py-1 hover:bg-blue-light-5 hover:text-blue-dark-1 focus:outline-none"
            @click="$emit('export')" data-testid="upload-button">
            Raw Data (.XLS)
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DownloadButton',
  emits: ['download', 'export'],
  props: {
    hasExportPermission: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    visibleDropdown: false,
  }),
  methods: {
    toggle() {
      this.visibleDropdown = !this.visibleDropdown;
    },
    closeDropdown() {
      this.visibleDropdown ? (this.visibleDropdown = false) : null;
    },
  }
}
</script>