<template>
  <div>
    <div>
      <p class="text-md mx-2">
        Showing {{ this.mappedList.length }} out of {{this.participantsTotalCount}} Participants
      </p>
    </div>
    <div class="flex justify-between items-center mx-2 mt-1">
      <InputField placeholder="Name, job role or team" label="Filter by" :propValue="this.filter.keyword"
        :onChange="setKeyword" leftIcon class="w-inputField" data-testid="filter-field" />

      <div class="w-2/4 flex flex-row justify-between items-end">
        <Select :options="this.filters" :onSelect="setValue" label="Filter by Status" objects class="ml-auto mr-2" />
        <Button text="Export Data" type="primary" size="medium" :iconLeft="downloadIcon"
          @click.native="exportPeopleData" />
      </div>
    </div>
    <div class="mx-2 my-3">
      <div class="flex flex-row w-full bg-grey-light-3 py-1 pl-2 rounded">
        <div class="flex flex-row items-center focus:outline-none">
          <p class="text-sm-2 text-grey-light">STATUS</p>
        </div>
        <button 
          class="w-1/3 flex flex-row items-center focus:outline-none" 
          @click="sortCol('NAME', 0)"
          >
          <p class="text-sm-2 text-grey-light mr-0.4">FULL NAME</p>
          <img
          src="../../../../assets/img/delta-table/caret.svg"
          class="transition-all"
            :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
        />
        </button>
        <button
        class="flex flex-row items-center w-1/5 focus:outline-none"
        @click="sortCol('TEAMNAME', 1)"
      >
          <p class="text-sm-2 text-grey-light mr-0.4">TEAM</p>
          <img
          src="../../../../assets/img/delta-table/caret.svg"
          class="transition-all"
            :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
        />
        </button>
        <button
        class="flex flex-row items-center w-1/4 justify-center focus:outline-none"
        @click="sortCol('ROLE', 2)"
      >
          <p class="text-sm-2 text-grey-light mr-0.4">JOB ROLE</p>
          <img
          src="../../../../assets/img/delta-table/caret.svg"
          class="transition-all"
            :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
        />
        </button>
        <button
        class="flex flex-row items-center w-1/5 justify-center focus:outline-none"
        @click="sortCol('STATUS', 3)"
      >
          <p class="text-sm-2 text-grey-light mr-0.4">ASSESSMENT STATUS</p>
          <img
          src="../../../../assets/img/delta-table/caret.svg"
          class="transition-all"
            :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null"
        />
        </button>
        <div
        class="flex flex-row items-center w-1/5 justify-center focus:outline-none"
        v-if="showSelectUserButton"
      >
          <p class="text-sm-2 text-grey-light mr-0.4">ACTION</p>
        </div>
      </div>
      <VirtualList 
        style="max-height: 364px; overflow-y: auto" 
        :data-key="'name'" 
        :data-sources="mappedList"
        :data-component="itemComponent" 
        data-testid="in-progress-participants-table"
        @tobottom="triggerFetch"
        :extra-props="{ showSelectUserButton: showSelectUserButton }"
        />
    </div>
  </div>
</template>

<script>
import TableItem from "./TableItem";
import VirtualList from 'vue-virtual-scroll-list';
import { mapActions, mapState } from "vuex";
import downloadIcon from "@/assets/img/icons/download.svg";
import debounce from "lodash/debounce";

export default {
  name: "Table",
  components: {
    TableItem,
    VirtualList,
  },
  props: {
    showSelectUserButton: {type: Boolean, default: false}
  },
  data: () => ({
    downloadIcon,
    itemComponent: TableItem,
    rotationMatrix: [1, 1, 1, 1],
    mappedList: [],
    pageNr: 1,
    pageSz: 20,
    sorting: {},
    filter: {
      label: "All statuses",
      value: "ALL",
      keyword: "",
    },
    filters: [
      {
        label: "All statuses",
        value: "ALL",
      },
      {
        label: "Not started",
        value: "NOT_STARTED",
      },
      {
        label: "In progress",
        value: "IN_PROGRESS",
      },
      {
        label: "Completed",
        value: "COMPLETED",
      },
    ],
  }),
  computed: {
    ...mapState({
      participants: (state) => state.companyResults.teamProgress,
      participantsTotalCount: (state) => state.companyResults.teamProgressTotalCount,
    }),
    formatFilter() {
      switch (this.filter.label) {
        case "All statuses":
          return '';
        case "Not started":
          return 1;
        case "In progress":
          return 2;
        case "Completed":
          return 3;
        default:
          return;
      }
    },
  },
  methods: {
    ...mapActions({
      exportPeople: "companyResults/exportSurveyPeople",
      getProgress: "companyResults/getProgress",
    }),
    setKeyword: debounce(function (value) {
      this.filter.keyword = value;
      this.updateList(true);
    }, 500),
    setValue(option) {
      this.filter.value = option.value;
      this.filter.label = option.label;
      this.updateList(true)
    },
    exportPeopleData() {
      this.exportPeople({ surveyId: this.$route.params.id, status: this.formatFilter });
    },
  
    async updateList(reset) {
      if (reset) {
        this.mappedList = [];
        this.pageNr = 1;
      }
      const payload = {
        keyword: this.filter.keyword,
        pageNr: this.pageNr,
        pageSz: this.pageSz,
        sorting: this.sorting,
        status: this.formatFilter || 0,
      };
      await this.getProgress({
        surveyId: this.$route.params.id,
        ...payload,
      });

      this.mappedList = [...this.mappedList, ...this.participants];
    },
    sortCol(col, index) {
      this.rotationMatrix[index] *= -1;
        switch (col) {
          case "NAME":
            this.sorting = {firstName: this.rotationMatrix[index] === -1 ? 1 : 2}
            break;
          case "TEAMNAME":
            this.sorting = {team: this.rotationMatrix[index] === -1 ? 1 : 2}
            break;
          case "ROLE":
            this.sorting = {jobRole: this.rotationMatrix[index] === -1 ? 1 : 2}
            break;
          case "STATUS":
            this.sorting = {assessmentStatus: this.rotationMatrix[index] === -1 ? 1 : 2}
            break;
          default:
            break;
      }
      this.updateList(true)
    },
    triggerFetch() {
      if (this.pageNr < Math.ceil(this.participantsTotalCount / this.pageSz)) {
        this.pageNr++;
        this.updateList();
      }
    },
  },
};
</script>

<style></style>
