<template>
  <div class="flex space-x-1 mt-2">
    <SmallInfoCard
      v-for="(stat, index) in formattedStats"
      :key="index"
      :icon="stat.icon"
      :data="stat.data"
    />
  </div>
</template>

<script>
import SmallInfoCard from "@/components/miscellaneous/SmallInfoCard";
import inProgress from "../../../assets/img/stats/inProgress.svg";
import completed from "../../../assets/img/stats/completed.svg";
import invited from "../../../assets/img/stats/invited.svg";
import completionRate from "../../../assets/img/stats/completionRate.svg";
import notStarted from "../../../assets/img/stats/notStarted.svg";
export default {
  name: "Stats",
  props: ["stats"],
  data: () => ({
    inProgress,
    completed,
    invited,
    completionRate,
    notStarted,
  }),
  components: {
    SmallInfoCard,
  },
  computed: {
    formattedStats() {
      const statsCopy = {
        invited: this.stats.invited,
        completed: this.stats.completed,
        inProgress: this.stats.inProgress,
        notStarted: this.stats.notStarted,
      };
      const formatted = Object.keys(statsCopy).map((key) => {
        let text = "";
        let icon = null;
        switch (key) {
          case "invited":
            text = "Invited";
            icon = this.invited;
            break;
          case "completed":
            text = "Completed";
            icon = this.completed;
            break;
          case "inProgress":
            text = "In Progress";
            icon = this.inProgress;
            break;
          case "notStarted":
            text = "Not Started";
            icon = this.notStarted;
            break;
          default:
            text = "";
            icon = null;
            break;
        }
        return {
          icon,
          data: {
            number: statsCopy[key],
            text: text,
          },
        };
      });
      formatted.push({
        icon: completionRate,
        data: {
          text: "Completion Rate",
          number: "33%",
        },
      });
      return formatted;
    },
  },
  mounted() {},
};
</script>

<style></style>
